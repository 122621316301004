$darkGreen: #008C35;
$lightGreen: #94C11C;
$lightGrey:rgba(0,0,0,.12);
$snackbar-red: #dc3545;
$red: #E3000F;
$yellow: #FFD400;
$brown: #F07D00;
$black: #000000;
$screen-sm-min: 640px;
$screen-md-min: 768px;
$screen-lg-min: 1024px;
$screen-xl-min: 1280px;
$screen-xxl-min: 1536px;

//Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
//Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
//Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
// Before large devices
@mixin lg-max {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

//Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

//Extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

// @font-face {
//     font-family: 'VAG-Rounded-Light';
//     src: url('/assets/fonts/VAGRounded-Light.woff') format('woff');
//     src: url('/assets/fonts/VAGRounded-Light.woff2') format('woff2');
// }

// @font-face {
//     font-family: 'VAG-Rounded-Bold';
//     src: url('/assets/fonts/VAGRounded-Bold.woff') format('woff');

// }
