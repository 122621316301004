/* You can add global styles to this file, and also import other style files */
@import "../../../../frontend/src/angular-web-app/src/theme/variable.scss";

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

html,
body {
  height: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Open Sans" !important;
  height: calc(100vh - 80px);
}

.material-icons {
  font-family: "Material Icons";
}

* {
  font-family: "Open Sans";
}

.mat-tab-label,
.mat-tab-link,
.mat-menu-item {
  font-family: "Open Sans" !important;
}

//administrator menu transparent underline
.administrator-wrapper {
  .mat-form-field-underline {
    color: transparent;
  }
}

.dropdown-wrapper, .mobile-dropdown-wrapper {

  .mat-form-field-appearance-legacy .mat-form-field-underline,
  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: white;
  }
}

.dropdown-wrapper, .mobile-dropdown-wrapper {
  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-color: white;
  }
}

.mat-raised-button {
  min-width: 88px !important;
}

//input field label styling when focus
.mat-form-field.mat-focused .mat-form-field-label {
  color: $darkGreen;
}

//input field underline styling
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: $darkGreen;
}

//input field underline color
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: $darkGreen;
  height: 2px !important;
}

//dropdown selection styling
.mat-option:hover:not(.mat-option-disabled) {
  color: $lightGreen;
  background-color: transparent;
}

//dropdown selected item text color
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: white;
  background-color: $lightGreen;
}

//dropdown selection styling when active
.mat-option.mat-active {
  color: white;
  background-color: $lightGreen;
}

//multiple dropdown selection styling when active
.mat-option.mat-option-multiple.mat-active {
  color: $lightGreen;
  background-color: transparent;
}

//textarea color styling
textarea {
  color: $darkGreen !important;
}

//spinner styling
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $darkGreen;
}

//checkbox styling on multiselect
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background-color: $lightGreen;
}

//normal checkbox styling
.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background-color: $lightGreen;
}

//slide toggle slidder styling
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: $lightGreen;
}

//slide toggle slidder button styling
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: $darkGreen;
}

//input text styling on disabled
.mat-input-element:disabled,
.mat-select-disabled .mat-select-value {
  color: black;
}

input {
  color: $darkGreen !important;
}

//scrollbar height
div::-webkit-scrollbar,
tbody::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

//scrollbar track styling
div::-webkit-scrollbar-track,
tbody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.mat-select-panel.no-scroll {
  max-height: fit-content;
}

@media only screen and (max-width: $screen-sm-min) {
  .mat-select-panel {
    max-width: calc(100vw - 48px) !important;
  }
}

//scrollbar thumb styling
div::-webkit-scrollbar-thumb,
tbody::-webkit-scrollbar-thumb {
  background-color: $lightGrey;
  border-radius: 10px;
}

th.mat-header-cell {
  background-color: #f7f7f7;
  border-bottom-style: none !important;
  color: $darkGreen;
}

th.share-settings-header.mat-header-cell {
  background-color: transparent;
  border-bottom-style: none !important;
  color: black;
  font-weight: bold;
  font-size: 14px;
}

//table header alignment
.mat-sort-header-container {
  justify-content: flex-start;
}

td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
  padding-left: 10px !important;
}

td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type,
th.mat-header-cell:last-of-type {
  padding-right: 10px !important;
}

// td.mat-cell {
//   padding: 0 10px !important;
// }

.document-table {
  td.mat-cell {
    padding: 0 !important;
  }
}

tr:last-child {

  td.mat-cell,
  td.mat-footer-cell {
    border-bottom: none;
  }
}

td.input-cell.mat-cell {
  border-bottom-style: none;
}

mat-dialog-container {
  position: relative;

  .material-icons {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}

//srcolling snackbar styling
.mat-snack-bar-handset .mat-snack-bar-container {
  width: auto !important;
}

//scrolling snackbar background styling
.mat-snack-bar-handset {
  display: flex;
  align-content: center;
  justify-content: center;
}

//snackbar text color
.mat-simple-snackbar {
  color: white;

  .mat-simple-snackbar-action {
    .mat-button-wrapper {
      color: white;
    }
  }
}

//snackbar container styling
.mat-snack-bar-container {
  background-color: $snackbar-red;
  margin-top: calc(8px + env(safe-area-inset-top, 0px)) !important;

  &.green {
    background: $darkGreen;
  }

  &.yellow {
    background: $yellow;
    .mat-simple-snackbar {
      color: black;

      .mat-simple-snackbar-action {
        .mat-button-wrapper {
          color: black;
        }
      }
    }
  }

  &.orange {
    background: $brown;
  }
}

//menu item align horizontally center
button.mat-menu-item {
  text-align: center;
}


//dropdown arrow styling
.stable-header>.mat-expansion-indicator:after {
  color: $lightGreen;
}

//line under tab styling
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $darkGreen;
}

//stepper selected color
.mat-step-icon-selected {
  background-color: $lightGreen !important;
}

//stepper background color
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, .38);
}

//remove mat stepper header background color
.mat-step-header:hover {
  background-color: transparent;
}

//remove stepper header background color
.mat-step-header.cdk-program-focused {
  background-color: transparent;
}

//resize mat-checkbox size
.mat-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
}

//common dropdown icon styling
.mat-select-arrow-wrapper {
  .mat-select-arrow {
    background: url("/assets/images/icon/arrowdown.svg");
    // background-color: $darkGreen;
    // opacity: 0.54;
    width: 24px;
    height: 24px;
    border: none;
  }
}

.mat-select {
  height: 24px;

  .mat-select-value {
    height: 24px;
  }
}

//dropdown icon color styling when invalid
.invalid {
  .mat-select-arrow-wrapper {
    .mat-select-arrow {
      background: url("/assets/images/icon/arrowdown_red.svg");
      // background-color: #f44336;
      // opacity: 0.54;
      width: 24px;
      height: 24px;
      border: none;
    }
  }
}

//header dropdown icon styling
.dropdown-wrapper, .mobile-dropdown-wrapper {
  .mat-select-arrow-wrapper {
    .mat-select-arrow {
      background-image: url("/assets/images/icon/arrowdown_white.png");
      background-size: 24px;
      opacity: 1;
    }
  }
}

//error field underline
.error {
  .mat-form-field-underline {
    background-color: #f44336;
  }

  input {
    color: red !important;
  }
}

.text-area {
  .mat-form-field-underline {
    display: none;
  }
}

.vki-request-container {
  .mat-form-field-infix {
    border-top: none;
  }
}

//search field for mat select styling
input.search {
  padding: 16px 36px 16px 16px;
  width: 80px;
}

//mat selected value styling
.mat-select-value-text {
  color: $darkGreen;
}

//header mat selected value styling
.dropdown-wrapper, .mobile-dropdown-wrapper {
  .mat-select-value-text {
    color: white;
  }

  .mat-select-disabled .mat-select-value {
    color: white;
  }

  .mat-select-arrow-wrapper {
    .mat-select-arrow {
      // background-color: white;
    }
  }
}

//custom form field width for certain field
.custom-form {
  width: 140px;
}

.custom-form.long {
  width: auto;
}

//timepicker field padding
.custom-form.time {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      padding-bottom: 9px;
    }
  }
}

.sharing-form.time {
  .mat-form-field-wrapper {
    padding-bottom: 6px;

    .mat-form-field-flex {
      padding-bottom: 9px;
    }
  }
}

//very small form field width
.small-custom-form {
  width: 60px;
}

.small-custom-form.text {
  .mat-form-field-wrapper {
    padding-bottom: 10px;

    .mat-form-field-flex {
      padding-bottom: 17px;
    }
  }
}

//full width form field
.normal-custom-form {
  width: 100%;
}

.normal-custom-form.text {
  .mat-form-field-wrapper {
    padding-bottom: 10px;

    .mat-form-field-flex {
      padding-bottom: 17px;
    }
  }
}

//stepper header background styling
.control-error {

  .mat-step-header .mat-step-icon,
  .mat-step-header .mat-step-icon-not-touched {
    background-color: red;
  }
}

.mat-step-icon,
.mat-step-icon-not-touched {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-error {
  color: #cd000f;
  font-size: 85%;
}

//vki upload document button styling
.upload-button {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//spinner styling inside some container styling
.inside-container {
  .spinner-modal {
    position: unset !important;
  }
}

//critical selected medicine styling on vki medication step 5
.critical {
  .mat-select-value-text {
    color: #cd000f;
  }
}

//admin-vki filter field styling
.admin-vki-filter {
  .mat-form-field-infix {
    width: 130px;
  }
}

//vki-admin search filter styling
.search-filter {
  .mat-form-field-infix {
    padding-bottom: 17px;
  }
}

//timepicker field padding for vkiadmin
.filter-container {
  .time {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        padding-bottom: 12px;
        width: 130px;
      }
    }
  }
}

.stable-table {
  .mat-form-field-wrapper {
    padding-right: 10px;
  }
}

//badge color
.mat-badge-content {
  background: $darkGreen !important;
}

//contact form title styling
h4 {
  margin: 0px;
  padding-bottom: 15px;
}

//radio button bullet styling
.mat-radio-button.mat-primary .mat-radio-inner-circle {
  background-color: $lightGreen;
}

//radio button border styling
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: $lightGreen;
}

//reduce padding for vki-check form
.vki-check-container {
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 5px;
  }
}

.vki-info {
  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding-bottom: 19px;
    }
  }
}

.time {
  .mat-form-field-infix {
    .mat-form-field-label-wrapper {
      .mat-form-field-label {
        color: lightgrey !important;
      }
    }
  }
}

.width-90 {
  .mat-form-field-infix {
    width: 100% !important;
  }
}

.safe-area-lr {
  padding-right: env(safe-area-inset-right, 20px);
  padding-left: env(safe-area-inset-left, 20px);
}
